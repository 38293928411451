<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;1,500&family=Quicksand:wght@400;500;700&display=swap');

h1 {
  font-family: 'Lora', serif;
  font-weight: normal;
}

h3 {
  font-family: 'Lora', serif;
  font-weight: 500;
  font-style: italic;
}

body {
  font-family: 'Lora', serif;
  font-family: 'Quicksand', sans-serif;
  margin: 0px;
  font-size: 2.5vmin;
  overflow-x: hidden;
}
</style>
